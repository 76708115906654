import Datepicker from "./datepicker";
import Selectbox from "./selectbox";
import Tooltip from "./tooltip";

const Behaviors = {
  Datepicker,
  Selectbox,
  Tooltip,
};

export default Behaviors;
