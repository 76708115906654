/**
 * @fileoverview Webpack entrypoint.
 */

/* eslint-disable */

import mermaid from "mermaid";
import ReactOnRails from "react-on-rails";
import Relevant from "components/relevant";
import { redToast } from "components/reusable/relevant_toast";
import React from "react";
import "console-polyfill"; // Avoid IE9 errors from some dependencies.
import "raf/polyfill"; // provides requestAnimationFrame, a dependent of React 16, to older browsers.
// Provides Promise, Set, and other ECMAScript features to browsers like IE 11.
import "core-js/stable";
// Provides `async` to browsers like IE 11
import "regenerator-runtime/runtime";
// Provides smooth scrolling options when using the DOMElement.scrollIntoView() function
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
import loadable from "@loadable/component";
import "whatwg-fetch";

window.Relevant = Relevant;
window.mermaid = mermaid;

import apolloEntryPoint from "containers/apollo_entry_point";

/**
 * The following object contains all of the React components
 * loaded by Rails views. In general, a single React controller-view
 * is loaded by a single Rails view, in which case their paths will be
 * related as follows:
 * Rails:                   /app/views/path/to/view.html.haml
 * React: /app/javascript/controller_views/path/to/view/something.tsx
 */

// prettier-ignore
const components = {
  NewAlert: () => import("controller_views/alerts/new_alert"),
  EditAlert: () => import("controller_views/alerts/edit_alert"),
  CareGapsIndex: () => import("controller_views/pipeline/care_gaps/index/care_gaps_index"),
  MailingListsControllerView: () => import("controller_views/mailing_lists/mailing_lists_controller_view"),
  MeasuresIndex: () => import("controller_views/pipeline/measures/index/measures_index"),
  NewAcquisitionAgentPage: () => import("controller_views/pipeline/acquisition_agents/new_acquisition_agent_page"),
  NewTransformer: () => import("controller_views/pipeline/transformers/new_transformer"),
  EditTransformer: () => import("controller_views/pipeline/transformers/edit_transformer"),
  TransformersIndex: () => import("controller_views/pipeline/transformers/index/transformers_index"),
  LibraryTransformersIndex: () => import("controller_views/library/transformers/library_transformers_index"),
  LibraryReportsIndex: () => import("controller_views/library/reports/library_reports_index"),
  ShowLibraryTransformer: () => import("controller_views/library/transformers/show_library_transformer"),
  LibraryMeasuresIndex: () => import("controller_views/library/measures/library_measures_index"),
  ShowLibraryMeasure: () => import("controller_views/library/measures/show_library_measure"),
  LibraryDataElementsIndex: () => import("controller_views/library/data_elements/library_data_elements_index"),
  ShowLibraryDataElement: () => import("controller_views/library/data_elements/show_library_data_element"),
  Transformer: () => import("controller_views/pipeline/transformers/transformer"),
  ShowLibraryReport: () => import("controller_views/library/reports/show_library_report"),
  DataElementsIndex: () => import("controller_views/pipeline/data_elements/data_elements_index"),
  DataElementShow: () => import("controller_views/pipeline/data_elements/data_element_show"),
  EditDataElement: () => import("controller_views/pipeline/data_elements/edit_data_element"),
  NewCustomCareGap: () => import("controller_views/pipeline/care_gaps/new_custom_care_gap"),
  EditCustomCareGap: () => import("controller_views/pipeline/care_gaps/edit_custom_care_gap"),
  EditStandardCareGap: () => import("controller_views/pipeline/care_gaps/edit_standard_care_gap"),

  NewCustomMeasure: () => import("controller_views/pipeline/measures/new_custom_measure"),
  EditCustomMeasure: () => import("controller_views/pipeline/measures/edit_custom_measure"),
  EditStandardMeasure: () => import("controller_views/pipeline/measures/edit_standard_measure"),
  ShowMeasure: () => import("controller_views/pipeline/measures/show_measure"),

  OutreachCampaign: () => import("controller_views/outreach/campaign"),
  OutreachCampaignsIndex: () => import("controller_views/outreach/campaigns_index"),
  EditOutreachCampaign: () => import("controller_views/outreach/edit_campaign"),
  NewOutreachCampaign: () => import("controller_views/outreach/new_campaign"),
  NewOutreachAudience: () => import("controller_views/outreach/new_audience"),
  EditOutreachAudience: () => import("controller_views/outreach/edit_audience"),
  OutreachLanguagesIndex: () => import("controller_views/outreach/languages_index"),
  OutreachCampaignSteps: () => import("controller_views/outreach/campaign_steps"),

  NewDataUpload: () => import("controller_views/data_uploads/new_data_upload"),
  ShowDataUpload: () => import("controller_views/data_uploads/show_data_upload"),

  DataExplorersIndex: () => import("controller_views/data_explorers/data_explorers_index"),
  EditDataExplorer: () => import("controller_views/data_explorers/edit_data_explorer"),

  AllPipelines: () => import("controller_views/pipelines/all_pipelines"),
  ShowPipeline: () => import("controller_views/pipelines/show_pipeline"),

  NewReport: () => import("controller_views/report_builder/reports/new_report"),
  EditReport: () => import("controller_views/report_builder/reports/edit_report"),

  SourceDatabasesIndex: () => import("controller_views/pipeline/source_databases/source_databases_index"),

  AcquisitionPlansIndex: () => import("controller_views/pipeline/acquisition_plans/acquisition_plans_index"),

  HelpMenu: () => import("components/help_menu/help_menu"),

  PhiAccessSettings: () => import("controller_views/phi_access_settings/phi_access_settings"),

  DestinationSchemaInfoIcon: () => import("components/reusable/destination_schema_info_icon"),

  UdsPlusSubmissionsIndex: () => import("controller_views/uds_plus/submissions/uds_plus_submissions_index"),
  ShowUdsPlusSubmission: () => import("controller_views/uds_plus/submissions/show_uds_plus_submission"),
  ShowUdsPlusSubmissionResponses: () => import("controller_views/uds_plus/submission_responses/show_uds_plus_submission_responses"),
  ShowUdsPlusView: () => import("controller_views/uds_plus/views/show_uds_plus_view"),
  StyleGuideIndex: () => import("controller_views/style_guide/style_guide_index"),

  ShowProjectTeamSettings: () => import("controller_views/settings/project_team/show_project_team_settings"),
  EditProjectTeamSettings: () => import("controller_views/settings/project_team/edit_project_team_settings"),
  ShowUDSPlusSettings: () => import("controller_views/settings/uds_plus/show_uds_plus_settings"),
  EditUDSPlusSettings: () => import("controller_views/settings/uds_plus/edit_uds_plus_settings"),
  ShowSsoSettings: () => import("controller_views/settings/sso/show_sso_settings"),
  EditSsoSettings: () => import("controller_views/settings/sso/edit_sso_settings"),

  SessionTimeoutModal: () => import("components/sessions/session_timeout_modal"),

  RefreshStatusButton: () => import("components/reusable/refresh_status_button"),
  StandAloneSqlSchemaBrowser: () => import("components/reusable/stand_alone_sql_schema_browser"),
  RelevantLogo: () => import("components/reusable/relevant_logo"),
  StandardDefinition: () => import("components/reusable/standard_definition"),
  SearchPathInfoIcon: () => import("components/reusable/search_path_info_icon"),
  VisitCalendarExplorerControllerView: () => import("controller_views/calendar_explorers/visits/show/visit_calendar_explorer_controller_view"),
  UsageDataShow: () => import("controller_views/v2/usage_data/show/usage_data_show"),
  ArAgingControllerView: () => import("controller_views/dashboards/ar_aging/show/ar_aging_controller_view"),
  PaymentsControllerView: () => import("controller_views/dashboards/payments/show/payments_controller_view"),
  PaymentPercentageControllerView: () => import("controller_views/dashboards/payment_percentages/show/payment_percentage_controller_view"),
  QualityMeasureIndex: () => import("controller_views/dashboards/quality_measures/index/quality_measure_index"),
  QualityMeasureShow: () => import("controller_views/dashboards/quality_measures/show/quality_measure_show"),
  ProviderProductivityIndex: () => import("controller_views/dashboards/productivity/provider/index/provider_productivity_index"),
  ProviderProductivityShowControllerView: () => import("controller_views/dashboards/productivity/provider/show/provider_productivity_show_controller_view"),
  VisitGroupProductivityIndexControllerView: () => import("controller_views/dashboards/productivity/visit_group/index/visit_group_productivity_index_controller_view"),
  VisitGroupProductivityShowControllerView: () => import("controller_views/dashboards/productivity/visit_group/show/visit_group_productivity_show_controller_view"),
  CareGapResultsTableControllerView: () => import("controller_views/listings/care_gap_results/index/care_gap_results_table_controller_view"),
  ClaimsTableControllerView: () => import("controller_views/listings/claims/index/claims_table_controller_view"),
  MeasureResultsTableControllerView: () => import("controller_views/listings/measure_results/index/measure_results_table_controller_view"),
  PatientsTableControllerView: () => import("controller_views/listings/patients/index/patients_table_controller_view"),
  PaymentsTableControllerView: () => import("controller_views/listings/payments/index/payments_table_controller_view"),
  VisitsTableControllerView: () => import("controller_views/listings/visits/index/visits_table_controller_view"),
  PanelExplorerControllerView: () => import("controller_views/panel_explorer/show/panel_explorer_controller_view"),
  PatientControllerView: () => import("controller_views/patients/show/patient_controller_view"),
  NewLibraryReport: () => import("controller_views/library/reports/new_library_report"),
  EditLibraryReport: () => import("controller_views/library/reports/edit_library_report"),
  PopulationsIndex: () => import("controller_views/pipeline/populations/index/populations_index"),
  PipelineStepLatestRunControl: () => import("controller_views/pipeline/show/pipeline_step_latest_run_control"),
  ProviderPanelsIndex: () => import("controller_views/provider_panels/index/provider_panels_index"),
  RecordDiscrepanciesIndex: () => import("controller_views/record_discrepancies/record_discrepancies_index"),
  ReportsControllerView: () => import("controller_views/report_builder/reports/index/reports_controller_view"),
  Report: () => import("controller_views/report_builder/reports/show/report"),
  RiskModelsIndex: () => import("controller_views/pipeline/risk_models/index/risk_models_index"),
  RiskFactorCustomRiskModelForm: () => import("controller_views/risk_models/form/risk_factor_custom_risk_model_form"),
  ProviderUdsSqlQueries: () => import("controller_views/uds/provider_uds_sql_queries"),
  UdsTableZipCodes: () => import("controller_views/uds/uds_table_zip_codes"),
  ProviderUdsTable3AReport: () => import("controller_views/uds/provider_uds_table_3_a_report"),
  ProviderUdsTable3BReport: () => import("controller_views/uds/provider_uds_table_3_b_report"),
  ProviderUdsTable4Report: () => import("controller_views/uds/provider_uds_table_4_report"),
  ProviderUdsTable5Report: () => import("controller_views/uds/provider_uds_table_5_report"),
  ProviderUdsTable6AReport: () => import("controller_views/uds/provider_uds_table_6_a_report"),
  UdsTable6B: () => import("controller_views/uds/uds_table_6_b"),
  ProviderUdsTable7Report: () => import("controller_views/uds/provider_uds_table_7_report"),
  AppointmentSchedulesPage: () => import("controller_views/visit_planning/index/appointment_schedules_page"),
  VisitPlanningShow: () => import("controller_views/visit_planning/show/visit_planning_show"),
  PopulationExplorerShow: () => import("controller_views/population_explorer/show/population_explorer_show"),
  RiskModelRiskLevelAssignmentForm: () => import("controller_views/risk_model_risk_level_assignments/form/risk_model_risk_level_assignment_form"),
  SavedQueriesPage: () => import("controller_views/saved_queries/saved_queries_page"),
  Dashboard: () => import("controller_views/report_builder/dashboards/dashboard"),
  ReportButtonsToolbar: () => import("controller_views/report_builder/reports/show/report_buttons_toolbar.jsx"),
  PipelineAnalysis: () => import("controller_views/pipeline_analyses/pipeline_analysis"),
  NewExtractPage: () => import("controller_views/pipeline/extracts/new_extract_page"),
  EditExtractPage: () => import("controller_views/pipeline/extracts/edit_extract_page"),
  SqlSchemaBrowser: () => import("components/reusable/sql_schema_browser"),
  NewAcquisitionPlan: () => import("controller_views/pipeline/acquisition_plans/new_acquisition_plan"),
  EditAcquisitionPlan: () => import("controller_views/pipeline/acquisition_plans/edit_acquisition_plan"),
  AcquisitionPlanShowFields: () => import("controller_views/pipeline/acquisition_plans/acquisition_plan_show_fields"),
  UdsExportButton: () => import("controller_views/uds/uds_export_button"),
  MultiselectForServerRenderedPage: () => import("components/reusable/multiselect_for_server_rendered_page")
}

// Provides every registered component with an Apollo client for two reasons:
// 1) Removes need to import and call `apolloEntryPoint` in every component that uses apollo
// 2) Non-React related code (such as calling `apolloEntryPoint`) will cause hot-reloads to trigger
//    a full page refresh. Moving the `apolloEntryPoint` calls into here lets us avoid this in most
//    cases.
//    (more info: https://github.com/pmmmwh/react-refresh-webpack-plugin/blob/main/docs/TROUBLESHOOTING.md#edits-always-lead-to-full-reload)
Object.keys(components).forEach((key) => {
  components[key] = loadable(components[key], {
    resolveComponent: (module) => apolloEntryPoint(module.default),
  });
});

ReactOnRails.register(components);
